import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center w-full" }
const _hoisted_2 = { class: "mt-12" }
const _hoisted_3 = { class: "mt-12" }
const _hoisted_4 = { class: "mt-12" }
const _hoisted_5 = { class: "flex items-center text-gray-600 text-base" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!
  const _directive_aos_anchor = _resolveDirective("aos-anchor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "home__header mt-32 lg:mt-64",
          header: _ctx.cms.page_header_heading,
          "sub-header": _ctx.cms.page_header_content,
          "header-span": 5,
          "sub-header-span": 6
        }, null, 8, ["header", "sub-header"]),
        (_ctx.cms.video)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              fluid: "",
              class: "mt-16 md:mt-24"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_LoopingVideo, {
                    class: "home__video",
                    source: _ctx.cms.video.desktop_asset.permalink,
                    cover: _ctx.cms.video.video_cover
                  }, null, 8, ["source", "cover"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: 800,
          delay: 500
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-16" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, { heading: "" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cms.related_clients_label), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Partners, { class: "mt-16 md:mt-20" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: 800,
          delay: 500
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-16 md:mt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      heading: "",
                      innerHTML: _ctx.cms.related_cases_heading
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Projects, {
          data: _ctx.cms.related_cases,
          class: "mt-16 pb-24 md:pb-48",
          "second-column-offset": "",
          "use-h2-for-title": ""
        }, null, 8, ["data"])
      ]),
      _: 1
    }),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, {
          fluid: "",
          class: "pt-24 md:pt-48"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_Column, { span: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      type: "fade-up",
                      anchor: "three-concepts"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_heading
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })), [
                  [_directive_aos_anchor, void 0, "three-concepts"]
                ]),
                _createVNode(_component_Column, {
                  span: 2,
                  class: "mt-20 md:mt-24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      order: "1",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_1
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_AOS, {
                      order: "1",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_1
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  span: 2,
                  class: "mt-20 md:mt-24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      order: "2",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_2
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_AOS, {
                      order: "2",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_2
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  span: 2,
                  class: "mt-20 md:mt-24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      order: "3",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_3
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_AOS, {
                      order: "3",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_3
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { class: "mt-12 md:mt-24" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      order: "4",
                      anchor: "three-concepts",
                      "anchor-placement": "top-center",
                      step: 150
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Anchor, {
                          class: "text-base",
                          route: _ctx.Route.WhatWeDo,
                          underlined: "",
                          "show-arrow": ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cms.wwd_label), 1)
                          ]),
                          _: 1
                        }, 8, ["route"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives((_openBlock(), _createBlock(_component_Grid, {
          "reverse-on-mobile": "",
          class: "md:pt-48",
          centered: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { span: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_AOS, { type: "fade-up" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Heading, {
                      tag: "h2",
                      class: "sm:mt-12 md:mt-0 text-2xl-mo md:text-2xl",
                      innerHTML: _ctx.cms.au_heading
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AOS, {
                  order: "2",
                  anchor: "meet-people",
                  "anchor-placement": "top-center",
                  step: 150,
                  "is-group": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      class: "text-base text-gray-600 md:pr-8 sm:mt-12 md:mt-16 mb-12",
                      innerHTML: _ctx.cms.au_paragraph
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AOS, {
                  order: "3",
                  anchor: "meet-people",
                  "anchor-placement": "top-center",
                  step: 150
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_Anchor, {
                        route: _ctx.Route.About,
                        underlined: "",
                        "show-arrow": ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.cms.au_label), 1)
                        ]),
                        _: 1
                      }, 8, ["route"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: 2,
              class: "sm:mt-24 md:hidden"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Picture, {
                  image: _ctx.cms.au_images[0],
                  width: "100%",
                  height: "auto"
                }, null, 8, ["image"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              span: [ 2, 3 ],
              push: [ 0, 0, 1 ],
              container: "",
              class: "sm:hidden md:block"
            }, {
              default: _withCtx(() => [
                (_ctx.cms.au_images)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_Picture, {
                        image: _ctx.cms.au_images[0],
                        overflow: ""
                      }, null, 8, ["image"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })), [
          [_directive_aos_anchor, void 0, "meet-people"]
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}