

    import { defineComponent } from 'vue'
    import Anchor from '../components/Anchor.vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import Container from '../components/Container.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import Heading from '../components/Heading.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Partners from '../components/Partners.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Picture from '../components/Picture.vue'
    import Projects from '../components/Projects.vue'
    import LoopingVideo from '../components/LoopingVideo.vue'
    import { useCms } from '../compositions/UseCms'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useTheme } from '../compositions/UseTheme'
    import MetaInfo from '../components/MetaInfo.vue'
    import { useGrid } from '../compositions/UseGrid'

    export default defineComponent({
        name: 'Home',
        components: {
            MetaInfo,
            Heading,
            Grid,
            LoopingVideo,
            Column,
            Picture,
            PageHeader,
            Container,
            Anchor,
            Partners,
            Projects,
            ThemeChanger,
            // BlockRevealAnimation,
            Paragraph
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { home } = useCms()
            const { toggleGrid } = useGrid()

            return {
                cms: home,
                Route,
                Theme,
                toggleGrid
            }

        }
    })

